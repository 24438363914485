import React, { useState, useEffect, useMemo } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { ReactComponent as Circularplus } from '../../../assets/icons/circularplus.svg';
import { ReactComponent as Tick } from '../../../assets/icons/mark-black.svg';
import { ConfirmActionDialogue } from '../../../components';
import { Button } from '../../../components/buttons';
import NewAdmissionYearForm from '../../../components/forms/new-admission-year';
import ListEmptyContent from '../../../components/list-empty';
import { getStatusStyles, StatusContainer } from '../../../components/shared';
import { CenteredContainer } from '../../../containers/ScreenContainers';
import useDebounce from '../../../hooks/useDebounce';
import PageLayout from '../../../layout/page-layout/PageLayout';
import { getSavedAcademicStructure } from '../../../redux/academic-structure/actions';
import { closeAdmission, getAdmissionSessions } from '../../../redux/admissions/actions';
import { storeUnit } from '../../../utils/originUnit';

const fields = [
    {
        header: 'Session',
        accessorKey: 'admissionYear',
        cell: (props) => (
            <Link
                to={`/admissions/sessions/${props.row.original.id}`}
                state={{ state: props.getValue() }}
                style={{ color: '#2563EB' }}
            >
                {props.getValue()}
            </Link>
        ),
    },
    {
        header: 'Total Students',
        accessorKey: 'totalStudent',
        cell: (props) => <p>{props.getValue()}</p>,
    },
    {
        header: 'Total Accepted',
        accessorKey: 'accepted',
    },
    {
        header: 'Total Not Accepted',
        accessorKey: 'pending',
    },
    {
        header: 'Not Sent',
        accessorKey: 'notSent',
    },
    {
        header: 'Status',
        accessorKey: 'status',
        cell: (props) => (
            <StatusContainer style={getStatusStyles(props.getValue()?.toLowerCase())}>
                <span>{props.getValue()}</span>
            </StatusContainer>
        ),
    },
];

const Admissions = ({ unitName }) => {
    const dispatch = useDispatch();
    const { isLoading, sessions: admissionsSessions } = useSelector((state) => state.admission);

    const [searchValue, setSearchValue] = useState('');
    const debouncedSearchValue = useDebounce(searchValue, 700);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [showConfrimActionDialogue, setShowConfrimActionDialogue] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [selectedSession, setSelectedSession] = useState(null);

    const handleCloseAdmission = async (id) => {
        const res = await dispatch(closeAdmission(selectedSession?.id));
        if (res) {
            setShowConfrimActionDialogue(false);
            dispatch(getAdmissionSessions());
        }
    };

    useEffect(() => {
        storeUnit(unitName);
    }, [unitName]);

    useEffect(() => {
        dispatch(getSavedAcademicStructure());
    }, [dispatch]);

    const query = useMemo(() => {
        let queryParams = {};

        if (debouncedSearchValue) {
            queryParams.session = debouncedSearchValue;
        }

        return queryParams;
    }, [debouncedSearchValue]);

    useEffect(() => {
        dispatch(getAdmissionSessions(query));
    }, [dispatch, query]);

    return (
        <>
            <div>
                <PageLayout
                    pageTitle="Admissions"
                    secondaryButtonText="Create Session"
                    fields={fields}
                    data={admissionsSessions || []}
                    loading={isLoading}
                    isActionable
                    actionType="radio"
                    dataIdField="id"
                    onSelectedItemsChange={(items) => setSelectedSession(items[0])}
                    onSecondaryButtonClick={() => setShowForm(true)}
                    onActionBtnClick={() => setDropdownOpen(!dropdownOpen)}
                    actionOpen={dropdownOpen}
                    actionClose={() => setDropdownOpen(false)}
                    actionItems={[
                        {
                            icon: <Tick />,
                            name: 'Close Admission',
                            disabled: !selectedSession,
                            click: () => {
                                setShowConfrimActionDialogue(true);
                                setDropdownOpen(false);
                            },
                        },
                    ]}
                    searchable
                    searchValue={searchValue}
                    onSearchChange={(e) => setSearchValue(e.target.value)}
                    onSearchClose={() => setSearchValue('')}
                    noItemView={
                        <CenteredContainer className="mt-[5rem]">
                            <ListEmptyContent
                                isInTable
                                title={searchValue ? '' : 'No Sessions'}
                                subtitle={searchValue ? 'No sessions exist that match your search' : 'Create Session'}
                            >
                                {!searchValue && (
                                    <Button bgColor="#6366F1" color="#fff" onClick={() => setShowForm(true)}>
                                        <Circularplus className="mr-[1.15rem]" />
                                        Create Session
                                    </Button>
                                )}
                            </ListEmptyContent>
                        </CenteredContainer>
                    }
                />
            </div>

            <ConfirmActionDialogue
                show={showConfrimActionDialogue}
                title="Close Admission"
                isLoading={isLoading}
                subtitle="Are you sure you want to close this session?"
                close={() => setShowConfrimActionDialogue(false)}
                confirmAction={() => handleCloseAdmission()}
            />
            <NewAdmissionYearForm show={showForm} setShow={setShowForm} />
        </>
    );
};

export default Admissions;
