import React, { useState, useEffect, useMemo } from 'react';

import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, Link } from 'react-router-dom';

import { ReactComponent as Circularplus } from '../../../../assets/icons/circularplus-black.svg';
import { ReactComponent as DeleteIcon } from '../../../../assets/icons/delete-red.svg';
import { ReactComponent as EditIcon } from '../../../../assets/icons/edit-black.svg';
import CreateBlock from '../../../../components/forms/create-block';
import EditBlock from '../../../../components/forms/edit-block';
import { GoBack } from '../../../../components/go-back';
import ConfirmActionDialogueWithInput from '../../../../components/popups/confirm-action-with-input';
import useDebounce from '../../../../hooks/useDebounce';
import PageLayout from '../../../../layout/page-layout/PageLayout';
import { deleteBlock, getAllBlocks } from '../../../../redux/hostel/action';
import { storeUnit } from '../../../../utils/originUnit';

const HostelBlock = ({ unitName }) => {
    const dispatch = useDispatch();
    const {
        state: { _id: id, hostelName, hostelLogo },
    } = useLocation();

    const { isLoading, allBlocks } = useSelector((state) => state.hostel);

    const [toggleRefetch, setToggleRefetch] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const debouncedSearchValue = useDebounce(searchValue, 700);
    const [openModal, setOpenModal] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);

    const [inputValue, setInputValue] = useState('');

    const fields = [
        {
            cell: (props) => (
                <Link
                    to={`/student-affairs/accomodation-management/${id}/${props.row.original._id}`}
                    state={{
                        hostelName: hostelName,
                        _id: props.row.original._id,
                        totalRooms: props.row.original.totalRooms,
                        totalBedspaces: props.row.original.totalBedspaces,
                    }}
                    style={{ color: '#2563EB', cursor: 'pointer' }}
                >
                    {props?.getValue()}
                </Link>
            ),
            header: 'Block Name',
            accessorKey: 'blockName',
        },

        {
            cell: (props) => <p>{props?.getValue()}</p>,
            header: 'gender',
            accessorKey: 'gender',
        },
        {
            cell: (props) => <p>{props?.getValue()}</p>,
            header: 'No of Rooms',
            accessorKey: 'numberOfRooms',
        },
        {
            cell: (props) => <p>{props?.getValue()}</p>,
            header: 'No of Bedspaces',
            accessorKey: 'numberOfBedSpaces',
        },
    ];

    const handleDelete = async () => {
        const check = selectedItem?.blockName !== inputValue;
        if (check) return toast.error('Confirmation text does not match');
        if (!check) {
            const res = await dispatch(deleteBlock(selectedItem?._id));
            if (res) {
                toast.success('Block deleted successfully!');
                setToggleRefetch((prevState) => !prevState);
                setOpenModal(false);
            }
        }
    };

    const handleEdit = () => {
        if (!selectedItem?._id) return toast.error('Select a block to edit!');
        setOpenModal('edit');
    };
    const query = useMemo(() => {
        let queryParams = {};

        if (debouncedSearchValue) {
            queryParams.search = debouncedSearchValue;
        }

        return queryParams;
    }, [debouncedSearchValue]);
    useEffect(() => {
        dispatch(getAllBlocks(id, query));
    }, [dispatch, toggleRefetch, id, query]);

    useEffect(() => {
        storeUnit(unitName);
    }, [unitName]);

    return (
        <>
            <div>
                <div className="mb-[3rem]">
                    <GoBack title="Accomodation Management" subTitle={hostelName} />
                </div>
                <PageLayout
                    pageTitle={hostelName}
                    secondaryButtonText="Add Block"
                    secondaryButtonIcon={<Circularplus />}
                    logoSrc={hostelLogo?.url}
                    imageName={hostelName}
                    logo
                    onSecondaryButtonClick={() => setOpenModal('create')}
                    onActionBtnClick={() => setOpenModal('actions')}
                    actionOpen={openModal === 'actions'}
                    actionClose={() => setOpenModal(null)}
                    actionItems={[
                        {
                            icon: <EditIcon />,
                            name: 'Edit Block',
                            disabled: !selectedItem,
                            click: () => handleEdit(),
                        },

                        {
                            icon: <DeleteIcon />,
                            name: 'Delete Block',
                            disabled: !selectedItem,
                            click: () => {
                                setOpenModal('delete');
                            },
                        },
                    ]}
                    onSearchChange={(e) => setSearchValue(e.target.value)}
                    searchValue={searchValue}
                    onSearchClose={() => setSearchValue('')}
                    showTableUtils
                    showFilter
                    data={allBlocks || []}
                    fields={fields}
                    loading={isLoading}
                    width={'100%'}
                    isActionable
                    actionType="radio"
                    onSelectedItemsChange={(items) => setSelectedItem(items[0])}
                    openFilterPop={openModal === 'options-popup-main'}
                    closeFilterPop={() => setOpenModal(null)}
                />
            </div>
            <CreateBlock
                show={openModal === 'create'}
                setShow={setOpenModal}
                setToogleRefetch={() => setToggleRefetch((prevState) => !prevState)}
            />
            <EditBlock
                item={selectedItem}
                show={openModal === 'edit'}
                setSelected={selectedItem?._id}
                setShow={setOpenModal}
                setToogleRefetch={() => setToggleRefetch((prevState) => !prevState)}
            />
            <ConfirmActionDialogueWithInput
                show={openModal === 'delete'}
                title="Delete Block?"
                subtitle="This Block will be completely deleted"
                subtitle2="To confirm delete, enter the name of the block in the text field."
                placeholder="Input name of hostel"
                close={() => setOpenModal(null)}
                confirmAction={handleDelete}
                btn2Text="Yes, Delete"
                setInputValue={setInputValue}
                value={inputValue}
                isLoading={isLoading}
                btnDisabled={inputValue !== selectedItem?.blockName}
            />
        </>
    );
};
export default HostelBlock;
