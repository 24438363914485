import { toast } from 'react-hot-toast';

import { axiosInstance, currentAPI } from '../../config';
import { stringifyJSON } from '../../utils/useJSON';
import { getActiveSemester, getActiveSession } from '../admissions/actions';
import { getOrganizationProfile } from '../ict/actions';
import { SAVE_STAFF, SAVE_USER } from '../user/slice';

import { LOADING, STOP_LOADING } from './slice';

export const registerUser = (payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.post(`${currentAPI}admin/admin_onboarding`, payload);

        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const loginUser = (payload, navigate) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.post(`${currentAPI}admin/login`, payload);
        if (response.status === 200) {
            const { accessToken, data } = response.data;
            await stringifyJSON('token', accessToken);
            await dispatch(SAVE_USER(data));
            navigate('/dashboard');
            dispatch(getOrganizationProfile());
            dispatch(getActiveSession());
            dispatch(getActiveSemester());
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const loginStaff = (payload, navigate) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.post(`${currentAPI}admin/login`, payload);
        if (response.status === 200) {
            const { accessToken, data } = response.data;
            await stringifyJSON('token', accessToken);
            await dispatch(SAVE_STAFF(data));
            navigate('/dashboard');
            dispatch(getOrganizationProfile());
            dispatch(getActiveSession());
            dispatch(getActiveSemester());
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const createPassword = (payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.post(`${currentAPI}admin/create_password`, payload);
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const createStaffPassword = (payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.post(`${currentAPI}staff/create_staff_password`, payload);
        // const successMessage = 'Password successfully created!';
        if (response.status === 200) {
            // toast.success(successMessage);
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const forgetPassword = (payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.post(`${currentAPI}admin/forgot_password`, payload);
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage =
            error?.response?.data?.message || 'An error occurred, please check if you have entered the right email.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const resetPassword = (token, payload, userType) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.post(
            `${currentAPI}admin/reset_password/${token}?userType=${userType}`,
            payload,
        );
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const scheduleDemo = (payload) => async (dispatch) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.post(`${currentAPI}admin/schedule_demo`, payload);
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
