import React, { useEffect, useRef, useState } from 'react';

import { FieldArray, Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import tw from 'twin.macro';

import { ReactComponent as PlusCircle } from '../../../assets/icons/circularplus-blue.svg';
import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { ReactComponent as UploadIcon } from '../../../assets/icons/upload-icon.svg';
import { Text } from '../../../containers/MesssageContainers';
import { FlexCentredRow, FlexRowEnd } from '../../../containers/ScreenContainers';
import { editNewHostel, getHostelById } from '../../../redux/hostel/action';
import { Button, LoadingButton } from '../../buttons';
import { GoBack } from '../../go-back';
import Select from '../../inputs/new-select';
import TextInput from '../../inputs/text-input';
import FormikTextInput from '../../inputs/text-input-with-formik';
import { CancelButton } from '../sharedStyles';

import { ValidationSchema } from './ValidationScema';

const Container = tw.div`
    md:p-[2.4rem]
    lg:p-[5.5rem]
    bg-white
    rounded-[0.8rem]
    mt-[3.6rem]
`;

const Details = tw.div`
    flex
    flex-row
    border
    border-dashed
    border-[#D1D5DB]
    rounded-[8px]
    items-center
    px-[.5rem]
    py-[.5rem]
    mb-[1rem]
    gap-[1rem]
`;

const AddButton = tw.div`
    bg-white
    w-full
    h-[4rem]
    flex
    justify-center
    items-center
    gap-[.5rem]
    border-[1px]
    border-[#3B82F6]
    rounded-[4px]
    bg-[#FFFFFF]
    shadow-[0px 1px 2px rgba(6, 25, 56, 0.05)]
    py-[.8rem]
    px-[1.6rem]
    cursor-pointer
`;
const Label = tw.label`
    text-[#1F2937]
    font-medium
    text-[1.4rem]
`;

const gender = ['Male', 'Female', 'Mixed'];
const partitionName = ['Block', 'Hall', 'Dormitory'];

const EditHostel = () => {
    const navigate = useNavigate();
    const {
        state: { selectedHostel },
    } = useLocation();
    const hiddenFileInput = useRef(null);
    const hiddenImagesInput = useRef(null);
    const dispatch = useDispatch();

    const { isLoading } = useSelector((store) => store.hostel);
    const [logoName, setLogoName] = useState(selectedHostel?.hostelLogo?.fileName || '');
    const [imageName, setImageName] = useState(
        selectedHostel?.hostelImages?.map((images) => {
            return images.fileName;
        }) || '',
    );

    useEffect(() => {
        setImageName(
            selectedHostel?.hostelImages?.map((images) => {
                return images.fileName;
            }),
        );
    }, [selectedHostel]);

    const handleClickImages = () => {
        hiddenImagesInput.current.click();
    };
    const handleClickLogo = () => {
        hiddenFileInput.current.click();
    };

    useEffect(() => {
        if (selectedHostel?._id) {
            dispatch(getHostelById(selectedHostel?._id));
        }
    }, [dispatch, selectedHostel?._id]);

    return (
        <>
            <div className="mb-12">
                <GoBack title="Accomodation Management" subTitle="Edit Hostel" />
            </div>

            <Text align="left" size="2.3rem" weight="600" bottom="3.2rem" top="3.2rem">
                Accomodation Management
            </Text>
            <Formik
                enableReinitialize={true}
                initialValues={{
                    hostelName: selectedHostel?.hostelName || '',
                    hostelAddress: selectedHostel?.hostelAddress || '',
                    gender: selectedHostel?.gender || '',
                    aboutHostel: selectedHostel?.aboutHostel || '',
                    hostelPartitionName: selectedHostel?.hostelPartitionName || '',
                    hostelPhoneNumber: selectedHostel?.hostelPhoneNumber || '',
                    hostelEmailAddress: selectedHostel?.hostelEmailAddress || '',
                    hostelLogo: selectedHostel?.hostelLogo?.url || {
                        url: selectedHostel || '',
                    },
                    hostelImages: selectedHostel?.hostelImages || [],
                }}
                validationSchema={ValidationSchema}
                onSubmit={async (values) => {
                    const formData = new FormData();
                    Object.keys(values).forEach((value) => {
                        if (value === 'hostelLogo') {
                            formData.append('hostelLogo', values.hostelLogo.url);
                        }
                        formData.append(value, values[value]);
                    });

                    values.hostelImages.forEach((image) => {
                        formData.append('hostelImages', image);
                    });

                    const headers = {
                        'Content-Type': 'multi-part/form-data',
                    };
                    const res = await dispatch(editNewHostel(formData, headers, selectedHostel?._id));
                    if (res) {
                        navigate(-1);
                    }
                }}
            >
                {({ handleChange, values, errors, setFieldValue }) => (
                    <Form>
                        <Container>
                            <div className="flex flex-col gap-[3.2rem]">
                                <TextInput
                                    label="Hostel Name"
                                    name="hostelName"
                                    type="text"
                                    placeholder="Hostel Name"
                                    errors={errors}
                                    onChange={handleChange}
                                    value={selectedHostel?.hostelName}
                                />
                                <div>
                                    <Label>Hostel Logo</Label>
                                    <Details>
                                        <Button
                                            type="button"
                                            onClick={handleClickLogo}
                                            className="px-[1.6rem] py-[0.8rem] border-dotted  border border-[#E5E7EB] shadow-[0_1px_2px_rgba(6,25,56,0.05)] "
                                        >
                                            Upload Files
                                        </Button>
                                        <Text size="1.6rem">{logoName || 'Click to upload files'}</Text>
                                        <input
                                            name="hostelLogo"
                                            type="file"
                                            accept=".jpg,.jpeg,.png"
                                            style={{ display: 'none' }}
                                            ref={hiddenFileInput}
                                            onChange={({ target: { files } }) => {
                                                if (files) {
                                                    setFieldValue('hostelLogo', files[0]);
                                                    setLogoName(files[0]?.name);
                                                } else if (selectedHostel?.hostelLogo) {
                                                    setFieldValue('hostelLogo', selectedHostel?.hostelLogo);
                                                }
                                            }}
                                        />
                                    </Details>
                                </div>

                                <FormikTextInput
                                    label="Hostel Address"
                                    name="hostelAddress"
                                    type="text"
                                    placeholder="Input Hostel Address"
                                    errors={errors}
                                    onChange={handleChange}
                                    value={values.hostelAddress}
                                />

                                <Select
                                    name="gender"
                                    objProp="gender"
                                    label="Gender"
                                    data={gender.map((item) => ({ gender: item }))}
                                    placeholder="Select Gender"
                                    onChange={(selected) => {
                                        setFieldValue('gender', String(selected[0].gender));
                                    }}
                                    error={errors.gender}
                                    passedSelectedItems={values.gender}
                                />
                                <Select
                                    name="hostelPartitionName"
                                    label="Hostel Partition Name (Nomenclature)"
                                    objProp="hostelPartitionName"
                                    data={partitionName.map((item) => ({ hostelPartitionName: item }))}
                                    placeholder="Hostel Partition Name"
                                    onChange={(selected) => {
                                        setFieldValue('hostelPartitionName', String(selected[0].hostelPartitionName));
                                    }}
                                    error={errors.hostelPartitionName}
                                    passedSelectedItems={values.hostelPartitionName}
                                />
                                <FormikTextInput
                                    name={'hostelPhoneNumber'}
                                    label="Hostel Phone Number"
                                    type="text"
                                    placeholder="Input Phone Number"
                                    errors={errors}
                                    onChange={handleChange}
                                    value={values.hostelPhoneNumber}
                                />
                                <FormikTextInput
                                    name="hostelEmailAddress"
                                    label="Hostel Email Address"
                                    type="text"
                                    placeholder="Input email address"
                                    errors={errors}
                                    onChange={handleChange}
                                    value={values?.hostelEmailAddress}
                                />
                                <FormikTextInput
                                    label="About Hostel"
                                    as="textarea"
                                    height="132px"
                                    className="py-[1rem]"
                                    name="aboutHostel"
                                    type="text"
                                    placeholder="Write a short information about the hostel."
                                    errors={errors}
                                    onChange={handleChange}
                                    value={values?.aboutHostel}
                                />
                                <div>
                                    <Label>Hostel Images</Label>

                                    <FieldArray
                                        name="hostelImages"
                                        render={({ remove, push }) => (
                                            <>
                                                {values?.hostelImages?.length > 0 ? (
                                                    values?.hostelImages?.map((file, index) => (
                                                        <div key={index}>
                                                            <Details
                                                                className="
                                                    justify-between"
                                                            >
                                                                <FlexCentredRow className="gap-[1rem]">
                                                                    <Button
                                                                        type="button"
                                                                        onClick={handleClickImages}
                                                                        className="px-[1.6rem] py-[0.8rem] border-dotted border border-[#E5E7EB] shadow-[0_1px_2px_rgba(6,25,56,0.05)]"
                                                                    >
                                                                        <UploadIcon className="mr-[1rem]" />
                                                                        Upload Files
                                                                    </Button>

                                                                    <Text size="1.6rem">
                                                                        {imageName[index] ||
                                                                            file?.fileName ||
                                                                            'Click to upload files'}
                                                                    </Text>
                                                                    <input
                                                                        name="hostelImages"
                                                                        type="file"
                                                                        accept=".pdf,.jpg,.jpeg,.png"
                                                                        multiple
                                                                        style={{ display: 'none' }}
                                                                        ref={hiddenImagesInput}
                                                                        onChange={(e) => {
                                                                            const files = Array.from(e.target.files);
                                                                            setFieldValue('hostelImages', [
                                                                                ...values.hostelImages,
                                                                                ...files,
                                                                            ]);
                                                                        }}
                                                                    />
                                                                </FlexCentredRow>
                                                                <CloseIcon onClick={() => remove(index)} />
                                                            </Details>
                                                            <FlexCentredRow className="w-[100%] md:w-[20rem] mt-[3.2rem]">
                                                                <AddButton
                                                                    onClick={() =>
                                                                        push({
                                                                            url: '',
                                                                        })
                                                                    }
                                                                >
                                                                    <PlusCircle />
                                                                    <Text
                                                                        weight="500"
                                                                        size="1.4rem"
                                                                        color="#3B82F6"
                                                                        style={{ width: '100%' }}
                                                                    >
                                                                        Add Another Image
                                                                    </Text>
                                                                </AddButton>
                                                            </FlexCentredRow>
                                                        </div>
                                                    ))
                                                ) : (
                                                    <Details
                                                        className="
                                            justify-between"
                                                    >
                                                        <FlexCentredRow className="gap-[1rem]">
                                                            <Button
                                                                type="button"
                                                                onClick={handleClickImages}
                                                                className="px-[1.6rem] py-[0.8rem] border-dotted border border-[#E5E7EB] shadow-[0_1px_2px_rgba(6,25,56,0.05)]"
                                                            >
                                                                <UploadIcon className="mr-[1rem]" />
                                                                Upload Files
                                                            </Button>

                                                            <Text size="1.6rem">{'Click to upload files'}</Text>
                                                            <input
                                                                name="hostelImages"
                                                                type="file"
                                                                accept=".pdf,.jpg,.jpeg,.png"
                                                                multiple
                                                                style={{ display: 'none' }}
                                                                ref={hiddenImagesInput}
                                                                onChange={(e) => {
                                                                    const files = Array.from(e.target.files);
                                                                    setFieldValue('hostelImages', [
                                                                        ...values.hostelImages,
                                                                        ...files,
                                                                    ]);
                                                                }}
                                                            />
                                                        </FlexCentredRow>
                                                    </Details>
                                                )}
                                            </>
                                        )}
                                    />
                                </div>
                            </div>
                            <FlexRowEnd className="gap-[2rem] my-[3.2rem]">
                                <CancelButton type="button" onClick={() => navigate(-1)}>
                                    Cancel
                                </CancelButton>
                                <LoadingButton
                                    loading={isLoading}
                                    disabled={isLoading}
                                    bgColor="#6366F1"
                                    color="#fff"
                                    type="submit"
                                >
                                    Update
                                </LoadingButton>
                            </FlexRowEnd>
                        </Container>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default EditHostel;
