import { toast } from 'react-hot-toast';

import { axiosInstance, currentAPI } from '../../config';
import { encodeQuery } from '../../utils';

import { GET_ADMISSIONS_SESSIONS, LOADING, STOP_LOADING, GET_ACTIVE_SEMESTER, GET_ACTIVE_SESSION } from './slice';

export const getAdmissionSessions = (query) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const queries = encodeQuery({ ...query });

        const response = await axiosInstance.get(`${currentAPI}admission/${currentUnit?._id}/all_session?${queries}`);

        if (response.status === 200) {
            const { data } = response?.data || {};
            let sessionsToUse;

            if (query) {
                sessionsToUse = data?.sessions;
            } else {
                sessionsToUse = data?.sessions
                    ?.slice()
                    ?.sort((a, b) => b?.admissionYear?.localeCompare(a?.admissionYear));
            }
            dispatch(GET_ADMISSIONS_SESSIONS(sessionsToUse));
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const createAdmissionSession = (payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.post(`${currentAPI}admission/${currentUnit?._id}/create_session`, payload);
        if (response.status === 200) {
            dispatch(getAdmissionSessions());
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

//
export const getSessionDetails = (sessionId, query) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const queries = encodeQuery({ ...query });

        const response = await axiosInstance.get(
            `${currentAPI}admission/${currentUnit?._id}/session/${sessionId}?${queries}`,
        );
        if (response.status === 200) {
            return response.data.data;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const closeAdmission = (id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.put(`${currentAPI}admission/${currentUnit?._id}/close_session/${id}`);
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const openAdmission = (id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.put(`${currentAPI}admission/${currentUnit?._id}/open_session/${id}`);
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const sendSingleAdmissionOffer = (id) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.post(`${currentAPI}admission/${currentUnit?._id}/send_offer_letter/${id}`);
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const sendMultipleAdmissionOffer = (selectedStudentIds) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.post(
            `${currentAPI}admission/${currentUnit?._id}/send_offer_letter_to_selected`,
            selectedStudentIds,
        );
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getActiveSession = () => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${currentAPI}admission/get/active_session`);
        if (response.status === 200) {
            const { data } = response.data;
            dispatch(GET_ACTIVE_SESSION(data.admissionYear));
        }
    } catch (error) {
        // const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        // toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getActiveSemester = () => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${currentAPI}admission/get/active_semester`);
        if (response.status === 200) {
            const { data } = response.data;
            dispatch(GET_ACTIVE_SEMESTER(data.semester));
        }
    } catch (error) {
        // const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        // toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getAcademicSessionDetails = (session) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.get(
            `${currentAPI}admission/${currentUnit?._id}/get_academic_session/${session}`,
        );
        if (response.status === 200) {
            const { data } = response.data;
            return data;
        }
    } catch (error) {
        // const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        // toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const revokeAdmissionOffer = (payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.post(
            `${currentAPI}admission/${currentUnit?._id}/revoke_offer_letter`,
            payload,
        );
        if (response.status === 200) {
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const generateMatricNumberToSelected = (session, payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.put(
            `${currentAPI}admission/${currentUnit?._id}/generate_matric_number/${session}`,
            payload,
        );
        if (response.status === 200) {
            toast.success('Matric number generated successfully');
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const generateMatricNumberToAll = (session) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.put(
            `${currentAPI}admission/${currentUnit?._id}/generate_matric_number_to_all_student/${session}`,
        );
        if (response.status === 200) {
            toast.success('Matric number generated successfully');
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const publishMatricNumber = (payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.put(
            `${currentAPI}admission/${currentUnit?._id}/publish_matric_number`,
            payload,
        );
        if (response.status === 200) {
            toast.success('Matric number published successfully');
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
export const overrideMatricNumber = (studentId, payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const {
            organisationUnits: { currentUnit },
        } = getState();

        const response = await axiosInstance.put(
            `${currentAPI}admission/${currentUnit?._id}/override_matric_number/${studentId}`,
            payload,
        );
        if (response.status === 200) {
            toast.success('Matric number updated successfully');
            return true;
        }
    } catch (error) {
        const errorMessage = error?.response?.data?.message || 'An error occurred, please try again.';
        toast.error(errorMessage);
    } finally {
        dispatch(STOP_LOADING());
    }
};
