import React, { useEffect, useState } from 'react';

import { Form, Formik } from 'formik';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import tw from 'twin.macro';

import { ReactComponent as ArrowDown } from '../../../assets/icons/arrow-down.svg';
import { ReactComponent as CircularPlus } from '../../../assets/icons/circlePlus.svg';
import { ReactComponent as Grid } from '../../../assets/icons/grid.svg';
import { ReactComponent as ArrowRight } from '../../../assets/icons/right-arrow.svg';
import { Text } from '../../../containers/MesssageContainers';
import { FlexCentredRow } from '../../../containers/ScreenContainers';
import { createPricingTier, getAllHostel } from '../../../redux/hostel/action';
import { storeUnit } from '../../../utils/originUnit';
import { LoadingButton } from '../../buttons';
import { GoBack } from '../../go-back';
import Checkbox from '../../inputs/checkbox';
import Select from '../../inputs/new-select';
import TextInput from '../../inputs/text-input-with-formik';
import { CancelButton } from '../sharedStyles';

const FlexContainer = tw.div`
    flex
    items-center
    gap-[2.4rem]
    mt-[2.9rem]
    justify-end
`;
const MainContainer = tw.div`
    bg-white
    rounded-lg
    p-[2.4rem]
    max-w-[100.5rem]
`;

const Title = tw.h2`
  text-[1.6rem]
  font-semibold
  leading-6
  text-[#1F2937]
  mb-[0]
`;

const Type = ['Hostel', 'Block', 'Rooms'];

const CreatePricingTier = ({ unitName }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { isLoading, allHostelsData } = useSelector((state) => state.hostel);
    const { data: allHostels } = allHostelsData;

    const [selectedType, setSelectedType] = useState('');
    const [selectedBlocks, setSelectedBlocks] = useState({});
    const [selectedHostels, setSelectedHostels] = useState([]);
    const [hostels, setHostels] = useState([]);
    const [openHostelIds, setOpenHostelIds] = useState({});
    const [openBlockIds, setOpenBlockIds] = useState({}); // Track which blocks are open

    const toggleBlock = (blockId) => {
        setOpenBlockIds((prev) => ({
            ...prev,
            [blockId]: !prev[blockId], // Toggle the specific block's open state
        }));
    };

    const toggleHostel = (hostelId) => {
        setOpenHostelIds((prev) => ({
            ...prev,
            [hostelId]: !prev[hostelId],
        }));
    };

    // Handling hostel selection
    const handleHostelCheck = (values, setFieldValue, key, hostelId) => {
        const updatedHostels = values.hostelIds.includes(hostelId)
            ? values.hostelIds.filter((id) => id !== hostelId)
            : [...new Set([...values.hostelIds, hostelId])];

        setFieldValue(key, updatedHostels);

        if (updatedHostels.includes(hostelId)) {
            // Add all blocks of this hostel to blockIds
            const hostel = allHostels.find((h) => h._id === hostelId);
            const allBlockIds = hostel?.blocks?.map((block) => block._id) || [];
            setFieldValue('blockIds', [...values.blockIds, ...allBlockIds]);
        } else {
            // Remove all blocks of this hostel from blockIds
            const hostel = allHostels.find((h) => h._id === hostelId);
            const allBlockIds = hostel?.blocks?.map((block) => block._id) || [];
            setFieldValue(
                'blockIds',
                values.blockIds.filter((id) => !allBlockIds.includes(id)),
            );
        }
    };

    // Handling block selection under a hostel
    const handleBlockCheck = (hostelId, blockId) => {
        const hostelBlocks = selectedBlocks[hostelId] || [];
        const updatedBlocks = hostelBlocks.includes(blockId)
            ? hostelBlocks.filter((id) => id !== blockId)
            : [...hostelBlocks, blockId];

        setSelectedBlocks((prev) => ({ ...prev, [hostelId]: updatedBlocks }));
    };

    // Handling room selection under a block
    const handleRoomCheck = (values, setFieldValue, roomId) => {
        const updatedRooms = values.roomIds.includes(roomId)
            ? values.roomIds.filter((id) => id !== roomId)
            : [...values.roomIds, roomId];

        setFieldValue('roomIds', updatedRooms);
    };

    useEffect(() => {
        const hostel = allHostels?.map((item) => ({
            _id: item?._id,
            name: item?.hostelName,
        }));
        setHostels(hostel || []);
    }, [allHostels]);

    useEffect(() => {
        dispatch(getAllHostel());
    }, [dispatch]);

    useEffect(() => {
        storeUnit(unitName);
    }, [unitName]);

    return (
        <>
            <div className="mb-12">
                <GoBack title="Pricing Tier" subTitle="Create New Tier" />
            </div>

            <Formik
                initialValues={{
                    name: '',
                    description: '',
                    type: '',
                    hostelIds: [],
                    blockIds: [],
                    roomIds: [],
                }}
                onSubmit={async (values, actions) => {
                    const headers = {
                        'Content-Type': 'multi-part/form-data',
                    };

                    const res = await dispatch(createPricingTier(values, headers));

                    if (res) {
                        toast.success('Pricing Tier created successfully!');
                        navigate(-1);
                    }
                }}
            >
                {({ errors, handleChange, values, setFieldValue }) => (
                    <Form>
                        <MainContainer>
                            <FlexCentredRow className="mb-[3.2rem] mt-[1.2rem] gap-[0.715rem]">
                                <CircularPlus fill="#6366F1" />
                                <Title> Create New Tier</Title>
                            </FlexCentredRow>
                            <div className="gap-y-[2.4rem] gap-x-[3.2rem] grid grid-cols-1 ">
                                <TextInput
                                    name="name"
                                    type="text"
                                    label="Name"
                                    placeholder="Input Name"
                                    value={values.name}
                                    onChange={handleChange}
                                    errors={errors}
                                />
                                <TextInput
                                    name="description"
                                    type="text"
                                    label="description"
                                    placeholder="Input description"
                                    value={values.description}
                                    onChange={handleChange}
                                    errors={errors}
                                />
                                <Select
                                    name="type"
                                    objProp="type"
                                    placeholder="Select Type"
                                    label="Select Type"
                                    data={Type?.map((item) => ({ type: item }))}
                                    onChange={(selected) => {
                                        if (selected?.length > 0 && selected[0]?.type) {
                                            setFieldValue('type', selected[0]?.type);
                                            setSelectedType(selected[0]?.type);
                                        } else {
                                            setFieldValue('type', '');
                                            setSelectedType('');
                                        }
                                    }}
                                    error={errors?.type}
                                    passedSelectedItems={values?.type}
                                />
                                {selectedType === 'Block' || selectedType === 'Rooms' ? (
                                    <Select
                                        name="hostelIds"
                                        multiSelect={true}
                                        objProp="name"
                                        placeholder="Select Hostel(s)"
                                        label="Select Hostel"
                                        useComponentState={false}
                                        data={hostels}
                                        onChange={(selected) => {
                                            if (selected?.length > 0) {
                                                setFieldValue('hostelIds', selected);
                                                setSelectedHostels(selected);
                                            } else {
                                                setFieldValue('hostelIds', []);
                                                setSelectedHostels([]);
                                            }
                                        }}
                                        error={errors?.hostelIds}
                                        passedSelectedItems={values?.hostelIds ? values?.hostelIds : null}
                                    />
                                ) : (
                                    ''
                                )}
                            </div>

                            {/* Conditional rendering based on type */}
                            {selectedType === 'Hostel' && (
                                <div>
                                    <FlexCentredRow className="items-center gap-4 flex mt-[3rem] mb-[2rem]">
                                        <Grid />
                                        <Text weight="600" size="1.9rem">
                                            Choose Available Hostels
                                        </Text>
                                    </FlexCentredRow>

                                    <div className="grid grid-cols-3 gap-4 justify-center items-center">
                                        {allHostels?.map((hostel) => (
                                            <div className="flex items-center" key={hostel?._id}>
                                                <Checkbox
                                                    label={hostel?.hostelName}
                                                    labelClassName="text-[1.4rem] font-medium leading-6"
                                                    value={hostel?._id}
                                                    checked={values?.hostelIds?.includes(hostel?._id)}
                                                    onChange={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        e.cancelBubble = true;
                                                        handleHostelCheck(
                                                            values,
                                                            setFieldValue,
                                                            'hostelIds',
                                                            hostel?._id,
                                                        );
                                                    }}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}

                            {selectedType === 'Block' && selectedHostels.length > 0 && (
                                <div>
                                    <FlexCentredRow className="items-center gap-4 flex mt-[3rem] mb-[2rem]">
                                        <Grid />
                                        <Text weight="600" size="1.9rem">
                                            Choose Available Blocks
                                        </Text>
                                    </FlexCentredRow>

                                    <div className="">
                                        {selectedHostels?.map((item) => {
                                            const hostelId = item?._id;
                                            const selectedHostel = allHostels?.find(
                                                (hostel) => hostel?._id === hostelId,
                                            );
                                            const isHostelChecked = values?.hostelIds?.includes(hostelId);
                                            const isOpen = openHostelIds[hostelId];

                                            return selectedHostel?.blocks?.length > 0 ? (
                                                <div key={hostelId} className="flex flex-col gap-6">
                                                    <FlexCentredRow className="gap-16 mt-[2rem]">
                                                        <Checkbox
                                                            label={item?.name}
                                                            labelClassName="text-[1.4rem] font-medium leading-6"
                                                            value={item?._id}
                                                            checked={isHostelChecked} // Check if hostel is selected
                                                            onChange={(e) => {
                                                                e.preventDefault();
                                                                e.stopPropagation();
                                                                handleHostelCheck(
                                                                    values,
                                                                    setFieldValue,
                                                                    'hostelIds',
                                                                    hostelId,
                                                                );

                                                                // Automatically select or deselect all blocks when hostel is checked/unchecked
                                                                const blockIds = selectedHostel?.blocks?.map(
                                                                    (block) => block?._id,
                                                                );
                                                                if (!isHostelChecked) {
                                                                    // If hostel is checked, select all associated blocks
                                                                    setFieldValue('blockIds', [
                                                                        ...values.blockIds,
                                                                        ...blockIds,
                                                                    ]);
                                                                } else {
                                                                    // If hostel is unchecked, remove all associated blocks
                                                                    setFieldValue(
                                                                        'blockIds',
                                                                        values?.blockIds?.filter(
                                                                            (blockId) => !blockIds.includes(blockId),
                                                                        ),
                                                                    );
                                                                }
                                                            }}
                                                        />
                                                        {isOpen ? (
                                                            <ArrowDown onClick={() => toggleHostel(hostelId)} />
                                                        ) : (
                                                            <ArrowRight onClick={() => toggleHostel(hostelId)} />
                                                        )}
                                                    </FlexCentredRow>
                                                    {isOpen && (
                                                        <div className="grid grid-cols-4 ml-[2.5rem] mt-[2rem]items-center">
                                                            {selectedHostel?.blocks?.map((block) => (
                                                                <div key={block?._id}>
                                                                    <Checkbox
                                                                        label={block?.blockName}
                                                                        labelClassName="text-[1.4rem] font-medium leading-6"
                                                                        value={block?._id}
                                                                        checked={selectedBlocks[hostelId]?.includes(
                                                                            block?._id,
                                                                        )}
                                                                        onChange={(e) => {
                                                                            e.preventDefault();
                                                                            e.stopPropagation();
                                                                            handleBlockCheck(
                                                                                values,
                                                                                setFieldValue,
                                                                                'blockIds',
                                                                                hostelId,
                                                                                block?._id,
                                                                            );
                                                                        }}
                                                                        disabled={isHostelChecked}
                                                                    />
                                                                </div>
                                                            ))}
                                                        </div>
                                                    )}
                                                </div>
                                            ) : (
                                                <div>
                                                    <Checkbox
                                                        label={item?.name}
                                                        labelClassName="text-[1.4rem] font-medium leading-6"
                                                        value={item?._id}
                                                        checked={selectedBlocks[hostelId]?.includes(hostelId)}
                                                        onChange={(e) => {
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                            handleHostelCheck(
                                                                values,
                                                                setFieldValue,
                                                                'blockIds',
                                                                hostelId,
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            )}

                            {selectedType === 'Rooms' && selectedHostels.length > 0 && (
                                <div>
                                    <FlexCentredRow className="items-center gap-4 flex mt-[3rem] mb-[2rem]">
                                        <Grid />
                                        <Text weight="600" size="1.9rem">
                                            Choose Available Rooms
                                        </Text>
                                    </FlexCentredRow>

                                    <div>
                                        {selectedHostels?.map((item) => {
                                            const hostelId = item?._id;
                                            const selectedHostel = allHostels?.find(
                                                (hostel) => hostel?._id === hostelId,
                                            );
                                            const isHostelChecked = values?.hostelIds?.includes(hostelId);

                                            const handleCheckboxChange = (blockIds, roomIds, isChecked) => {
                                                setFieldValue(
                                                    'blockIds',
                                                    isChecked
                                                        ? [...values.blockIds, ...blockIds]
                                                        : values?.blockIds?.filter(
                                                              (blockId) => !blockIds.includes(blockId),
                                                          ),
                                                );
                                                setFieldValue(
                                                    'roomIds',
                                                    isChecked
                                                        ? [...values.roomIds, ...roomIds]
                                                        : values?.roomIds?.filter(
                                                              (roomId) => !roomIds.includes(roomId),
                                                          ),
                                                );
                                            };

                                            return selectedHostel?.blocks?.length > 0 ? (
                                                <div key={hostelId} className="flex flex-col gap-6">
                                                    <div className="mt-[2rem]">
                                                        <Checkbox
                                                            label={item?.name}
                                                            labelClassName="text-[1.4rem] font-medium leading-6"
                                                            value={item?._id}
                                                            checked={isHostelChecked}
                                                            onChange={(e) => {
                                                                e.preventDefault();
                                                                e.stopPropagation();
                                                                handleHostelCheck(
                                                                    values,
                                                                    setFieldValue,
                                                                    'hostelIds',
                                                                    hostelId,
                                                                );
                                                                handleCheckboxChange(
                                                                    selectedHostel?.blocks?.map((block) => block?._id),

                                                                    selectedHostel?.blocks?.flatMap((block) =>
                                                                        block.rooms?.map((room) => room._id),
                                                                    ),
                                                                    !isHostelChecked,
                                                                );
                                                            }}
                                                        />
                                                    </div>
                                                    <div className="flex flex-col ml-[2.5rem] mt-[2rem]">
                                                        {selectedHostel?.blocks?.map((block) => {
                                                            const isBlockChecked = selectedBlocks[hostelId]?.includes(
                                                                block?._id,
                                                            );
                                                            const isBlockOpen = openBlockIds[block?._id];
                                                            return (
                                                                <div key={block?._id}>
                                                                    <FlexCentredRow className="gap-16 mb-[2rem]">
                                                                        <Checkbox
                                                                            label={block?.blockName}
                                                                            labelClassName="text-[1.4rem] font-medium leading-6"
                                                                            value={block?._id}
                                                                            checked={isBlockChecked}
                                                                            onChange={(e) => {
                                                                                e.preventDefault();
                                                                                e.stopPropagation();
                                                                                handleBlockCheck(hostelId, block?._id);
                                                                            }}
                                                                            disabled={isHostelChecked}
                                                                        />
                                                                        {isBlockOpen ? (
                                                                            <ArrowDown
                                                                                onClick={() => toggleBlock(block?._id)}
                                                                            />
                                                                        ) : (
                                                                            <ArrowRight
                                                                                onClick={() => toggleBlock(block?._id)}
                                                                            />
                                                                        )}
                                                                    </FlexCentredRow>
                                                                    {isBlockOpen && (
                                                                        <div className="grid grid-cols-4 justify-start ml-[2.5rem] mt-[1rem]">
                                                                            {block?.rooms?.map((room) => (
                                                                                <div
                                                                                    className="flex items-start"
                                                                                    key={room?._id}
                                                                                >
                                                                                    <Checkbox
                                                                                        label={room?.roomName}
                                                                                        labelClassName="text-[1.4rem] font-medium leading-6"
                                                                                        value={room?._id}
                                                                                        checked={values?.roomIds?.includes(
                                                                                            room?._id,
                                                                                        )}
                                                                                        onChange={(e) => {
                                                                                            e.preventDefault();
                                                                                            e.stopPropagation();
                                                                                            handleRoomCheck(
                                                                                                values,
                                                                                                setFieldValue,
                                                                                                room?._id,
                                                                                            );
                                                                                        }}
                                                                                        disabled={
                                                                                            isBlockChecked ||
                                                                                            isHostelChecked
                                                                                        }
                                                                                    />
                                                                                </div>
                                                                            ))}
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            ) : (
                                                <div>
                                                    <Checkbox
                                                        label={item?.name}
                                                        labelClassName="text-[1.4rem] font-medium leading-6"
                                                        value={item?._id}
                                                        checked={selectedBlocks[hostelId]?.includes(hostelId)}
                                                        onChange={(e) => {
                                                            e.preventDefault();
                                                            e.stopPropagation();
                                                            handleHostelCheck(
                                                                values,
                                                                setFieldValue,
                                                                'blockIds',
                                                                hostelId,
                                                            );
                                                        }}
                                                    />
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            )}
                        </MainContainer>
                        <FlexContainer>
                            <CancelButton type="button" onClick={() => navigate(-1)}>
                                Cancel
                            </CancelButton>
                            <LoadingButton
                                disabled={isLoading}
                                loading={isLoading}
                                bgColor="#6366F1"
                                color="#ffffff"
                                type="submit"
                            >
                                Create Tier
                            </LoadingButton>
                        </FlexContainer>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default CreatePricingTier;
